import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Portfolio from './Pages/Portfolio/Portfolio';
import Courses from './Pages/Courses/Courses';
import Header2 from './Components/Header2/Header2';
import MouseFollower from './Components/MouseFollower/MouseFollower';
import Coursedetails from './Pages/Courses/CourseDetails/Coursedetails';
import EnrollForm from './Components/EnrollForm/EnrollForm';
import PageNotFound from './PageNotFound';
import './App.css';

function App() {
  const location = useLocation();

  return (
    <>
      <MouseFollower />
      <Navbar />
      
      {location.pathname !== '*' && <Header2 />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/coursedetails" element={<Coursedetails />} />
        <Route path="/enrollment" element={<EnrollForm />} />
      </Routes>
      <Footer />
    </>
  );
}



export default App;
