import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Projects.css";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { project } from "../../data";
import { FaExternalLinkAlt } from "react-icons/fa"; 

const Projects = () => {
  const navigate = useNavigate();
  const [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 1000, 
    });

    const handleImageLoad = () => {
      setImagesLoaded((prev) => prev + 1);
    };

    const images = document.querySelectorAll(".project-img");

    images.forEach((img) => {
      img.addEventListener("load", handleImageLoad);
    });

    return () => {
      images.forEach((img) => {
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  const allImagesLoaded = imagesLoaded === project.length;

  return (
    <div className="project">
      <div className="project-title">
        Digital <span>Endeavors</span>
      </div>

      <div className="project-content">
        {project.map(({ img, title, desc, link }, index) => (
          <div
            key={index}
            className="project-item shadow-lg hover:shadow-2xl"
            data-aos="fade-up" // AOS animation
          >
            {!allImagesLoaded && (
              <div className="spinner-container">
                <BeatLoader color="#663399" />
              </div>
            )}
            <img
              src={img}
              alt=""
              className={`project-img ${allImagesLoaded ? 'loaded' : ''}`}
               data-aos="fade-up"
            />
            <span className="project-title"  data-aos="fade-up">{parse(title)}</span>
            <p className="project-desc"  data-aos="fade-up">{desc}</p>
            <button
              className="project-btn flex justify-center items-center space-x-2"
              onClick={() => window.open(link, '_blank')}
               data-aos="fade-up"
            >
              <span>Open</span>
              <FaExternalLinkAlt className="project-btn-icon" /> 
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
