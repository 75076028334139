import React, { useEffect } from "react";
import img1 from "../../assets/services/graphic2.jpg";
import img2 from "../../assets/services/graphic3.jpg";
import img3 from "../../assets/services/marketing.jpg";
import img4 from "../../assets/services/web1.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { CustomButtonGroupAsArrows } from "../../helper/CustomArrows";
const Services = () => {
  const data = [
    {
      text: "UI/UX Designing",
      subtext: "User Interfaces & Experiences",
      img: img1,
    },
    {
      text: "Web Development",
      subtext: "Building and Maintaining Websites",
      img: img4,
    },
    {
      text: "Digital Marketing",
      subtext: "Promoting Products and Services ",
      img: img3,
    },
    {
      text: "Graphic Designing",
      subtext: "Creating Visuals and Graphics",
      img: img2,
    },
  ];
  

  const responsive = {
    superLargeDesktop: {
     
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 800 },
      items: 2,
      partialVisibilityGutter: 40,
    },
   
    tablet: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, 
    
    });
  }, []);
  return (
    <div className="urbanist mb-6 w-full  relative" >
      <div className="flex flex-col lg:flex-row mx-4 m lg:mx-10 relative">
        <div className="w-full lg:w-[32%] ">
        <h1 className="flex h-fit items-center gap-2.5" data-aos="fade-right">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_13847)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.3087 10.3902H10.9406L16.1511 15.6007L15.5987 16.153L10.3882 10.9425V18.3107H9.61V10.9425L4.39949 16.153L3.84711 15.6007L9.05762 10.3902H1.68945V9.61195H9.05762L3.84711 4.40145L4.39949 3.84906L9.61 9.05957V1.69141H10.3882V9.05957L15.5987 3.84906L16.1511 4.40145L10.9406 9.61195H18.3087V10.3902Z"
                  fill="#5B5B5B"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.12418 2.82445C4.84203 2.82445 5.42391 3.40637 5.42391 4.12418C5.42391 4.84203 4.84199 5.42391 4.12418 5.42391C3.40637 5.42391 2.82445 4.84199 2.82445 4.12418C2.82445 3.40637 3.40637 2.82445 4.12418 2.82445ZM17.1755 4.12418C17.1755 4.84203 16.5936 5.42391 15.8758 5.42391C15.158 5.42391 14.5761 4.84199 14.5761 4.12418C14.5761 3.40637 15.158 2.82445 15.8758 2.82445C16.5936 2.82445 17.1755 3.40637 17.1755 4.12418ZM18.3096 11.2997C17.5918 11.2997 17.0099 10.7178 17.0099 10C17.0099 9.28215 17.5918 8.70027 18.3096 8.70027C19.0275 8.70027 19.6094 9.28219 19.6094 10C19.6094 10.7179 19.0275 11.2997 18.3096 11.2997ZM11.2997 1.69035C11.2997 2.4082 10.7178 2.99012 10 2.99012C9.28215 2.99012 8.70027 2.4082 8.70027 1.69035C8.70027 0.972539 9.28219 0.390625 10 0.390625C10.7179 0.390625 11.2997 0.972539 11.2997 1.69035ZM15.8758 17.1755C15.158 17.1755 14.5761 16.5936 14.5761 15.8758C14.5761 15.158 15.158 14.5761 15.8758 14.5761C16.5936 14.5761 17.1755 15.158 17.1755 15.8758C17.1755 16.5936 16.5936 17.1755 15.8758 17.1755ZM8.70027 18.3096C8.70027 17.5918 9.28219 17.0099 10 17.0099C10.7179 17.0099 11.2997 17.5918 11.2997 18.3096C11.2997 19.0275 10.7178 19.6094 10 19.6094C9.28215 19.6094 8.70027 19.0275 8.70027 18.3096ZM2.82445 15.8758C2.82445 15.158 3.40637 14.5761 4.12418 14.5761C4.84203 14.5761 5.42391 15.158 5.42391 15.8758C5.42391 16.5936 4.84199 17.1755 4.12418 17.1755C3.40637 17.1755 2.82445 16.5936 2.82445 15.8758ZM1.69035 8.70027C2.4082 8.70027 2.99012 9.28219 2.99012 10C2.99012 10.7179 2.4082 11.2997 1.69035 11.2997C0.972539 11.2997 0.390625 10.7178 0.390625 10C0.390625 9.28215 0.972539 8.70027 1.69035 8.70027Z"
                  fill="#103A33"
                ></path>
                <path
                  d="M13.6201 13.622C15.6205 11.6216 15.6205 8.37835 13.6201 6.37795C11.6197 4.37755 8.37636 4.37755 6.37596 6.37795C4.37555 8.37835 4.37555 11.6216 6.37596 13.622C8.37636 15.6225 11.6197 15.6225 13.6201 13.622Z"
                  fill="#EC4814"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.3078 12.074C12.3078 13.3482 7.69336 13.3482 7.69336 12.074C7.69336 10.7998 8.72645 9.76684 10.0007 9.76684C11.2748 9.76688 12.3078 10.7998 12.3078 12.074ZM8.9343 8.03883C8.9343 8.62777 9.41172 9.1052 10.0007 9.1052C10.5896 9.1052 11.067 8.62777 11.067 8.03883C11.067 7.45004 10.5896 6.97266 10.0007 6.97266C9.41168 6.97262 8.9343 7.45004 8.9343 8.03883Z"
                  fill="white"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.2543 11.5781C11.9358 12.6196 8.26301 12.6534 7.72703 11.6794C7.70488 11.8077 7.69336 11.9396 7.69336 12.0743C7.69336 13.3484 12.3078 13.3484 12.3078 12.0743C12.3078 11.9039 12.2894 11.7379 12.2543 11.5781Z"
                  fill="#1A2429"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1_13847">
                  <rect width="20" height="20" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
              <span className="text-xl font-semibold text-[#EC4814]">
                Services
              </span>
            </h1>
          <h2 className="text-[40px] leading-[45px] font-extrabold  text-heading capitalize mt-4 text-[#0E191E]"
            style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}} data-aos="fade-up">
            Enhance your <span className="text-[#EC4814]"> performance </span>{" "}
            with technology
          </h2>
          <ul className="text-lg text-paragraph flex flex-col gap-4 mt-9 text-[#0E191E]" data-aos="fade-up">
            <li>
              <span className="font-bold mr-[10px]"> 1.</span> UI/UX Designing
            </li>
            <li>
              <span className="font-bold mr-[10px]"> 2.</span> Web Development & Maintainance
            </li>
            <li>
              <span className="font-bold mr-[10px]"> 3.</span> Ongoing
              Maintenance
            </li>
            <li>
              <span className="font-bold mr-[10px]"> 4.</span> Digital Marketing
            </li>
          </ul>
         
        </div>
        <div className="w-full relative lg:static lg:w-[68%] mt-[90px] lg:mt-0 lg:ml-[30px]" >
          <Carousel
            swipeable={false}
            responsive={responsive}
            autoPlay
            infinite={true}
            partialVisible={true}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<CustomButtonGroupAsArrows />}
          >
            {data.map((product, i) => (
              <Link key={i} to="/services">
                <div className="cursor-pointer" >
                  <div className="relative ml-2 overflow-hidden group rounded-[20px] flex justify-center" >
                    <img
                      src={product.img}
                      alt="Services"
                      className="relative h-auto w-full lg:max-h-[450px]"
                      // data-aos="fade-left"
                    />
                    <div className="absolute bottom-[20px] bg-[#FFFAF0] w-10/12 rounded-[20px] py-4 px-5 translate-y-[130%] transition-all duration-300 group-hover:translate-y-0">
                      <h1 className="text-lg leading-8 font-bold text-heading">
                        {product.text}
                      </h1>
                      <p className="text-md text-paragraph mt-1">
                        {product.subtext}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Services;

