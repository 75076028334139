import React from "react";
import { Link } from "react-router-dom";
import { RiFacebookLine, RiInstagramLine } from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa6";
import { animateScroll as scroll } from "react-scroll";
import {
  BsArrow90DegUp,
  BsArrowRight,
  BsArrowUp,
  BsChevronRight,
  BsChevronUp,
} from "react-icons/bs";
import "./footer.css";

function Footer() {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <div className="urbanist pt-12 relative  md:pl-6 border-b-2 border-t-2 lg:pl-3 border-[#3b3b3b] bg-[#1b1b1b] min-h-[47vh] max-h-fit w-full text-white ">
        <div className="px-5 pt-20 md:grid flex flex-col justify-center mx-auto md:grid-cols-2 lg:grid-cols-4 gap-y-4 text-left">
          <div>
            <Link to="/">
              {/* <img src={logo} alt="" className='h-16 md:mt-[-10px] ml-[-18px]' /> */}
              Beta Communes
            </Link>
            <div className="md:mt-4">
              <div>
                Elevating businesses since 2022, we've crafted cutting-edge IT
                solutions for global success. Transform your digital journey
                with us today! Connect for Excellence
              </div>
            </div>
            <div>
              <p className="mr-3 flex-col mt-4 mb-2 flex">Follow Us</p>
              <div className="flex space-x-3">
                <div className="relative w-[32px] flex justify-center items-center h-[32px] border border-gray-400 rounded-sm overflow-hidden group">
                  <span className="absolute inset-0 bg-[#EC4814] transition-all duration-300 ease-in-out w-0 group-hover:w-full"></span>
                  <RiInstagramLine
                    size={20}
                    className="text-white relative z-10"
                  />
                </div>

                <div className="relative w-[32px] flex justify-center items-center h-[32px] border border-gray-400 rounded-sm overflow-hidden group">
                  <span className="absolute inset-0 bg-[#EC4814] transition-all duration-300 ease-in-out w-0 group-hover:w-full"></span>
                  <RiFacebookLine
                    size={20}
                    className="text-white relative z-10"
                  />
                </div>
                <div className="relative w-[32px] flex justify-center items-center h-[32px] border border-gray-400 rounded-sm overflow-hidden group">
                  <span className="absolute inset-0 bg-[#EC4814] transition-all duration-300 ease-in-out w-0 group-hover:w-full"></span>
                  <FaLinkedinIn 
                   size={20}
                   className="text-white relative z-10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="md:ml-7" style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}>
            <h2 className="pb-8 font-bold text-[20px]  text-white md:ml-6">
              LINKS
            </h2>
            <ul className="ml-[-1.5rem] md:ml-0">
              <li className="pb-5 ">
                <Link
                  to="/"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium  hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  Home
                </Link>
              </li>
              <li className="pb-5 ">
                <Link
                  to="/aboutus"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  About Us
                </Link>
              </li>
              <li className="pb-5 ">
                <Link
                  to="/contactus"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  Contact Us
                </Link>
              </li>
              <li className="pb-5 ">
                <Link
                  to="/portfolio"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  Portfolio
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="pb-8 font-bold text-[20px]  text-white md:ml-4" 
              style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"}}
            >
              Other Links
            </h2>
            <ul className="md:mb-20 md:ml-0 ml-[-1.5rem]">
              <li className="pb-5 ">
                <Link
                  to="/courses"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  Courses
                </Link>
              </li>
              <li className="pb-5 ">
                <Link
                  to="/enrollment"
                  className="cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  Enrollment
                </Link>
              </li>
              {/* <li className="pb-5 ">
                <Link
                  to="/blogs"
                  className="cursor-pointer hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out flex items-center group"
                  onClick={handleLinkClick}
                >
                  <BsChevronRight className="mr-2 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-100" />
                  BLOGS
                </Link>
              </li> */}
              <li className="pb-5  ">
                <Link
                  to="brands"
                  className=" cursor-pointe hover:ml-2 hover:font-semibold font-medium hover:text-[#EC4814] transition-all duration-300 ease-in-out"
                >
                  {" "}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className="text-white   ">
              <h3 className="pb-6 font-bold text-[20px]  text-white md:ml-4">
                Newsletter
              </h3>
              <p className="text-white mb-4">
                Stay up-to-date with our latest news and offers!
              </p>
              <form
                action="/subscribe"
                method="post"
                className="flex items-center space-x-2"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  className="px-4 py-2 border bg-[#1b1b1b] border-gray-700 rounded-l-md focus:outline-none focus:border-[#EC4814] flex-grow"
                  required
                />
                <button
                  type="submit"
                  className="bg-[#ec5c14] cursor-pointer  font-bold hover:bg-[#EC4814] text-white px-4 py-3 rounded-r-md transition-colors duration-300 ease-in-out"
                >
                  <BsArrowRight size={18} />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end my-6 md:mt-[-40px] px-10 md:px-20">
          <div
            className="flex justify-center items-center cursor-pointer w-[50px] h-[50px] font-bold rounded-full bg-[#EC4814] text-white"
            onClick={handleLinkClick}
          >
            <BsArrowUp size={20} />
          </div>
        </div>
      </div>
      <div className="px-5 urbanist flex-col md:flex-row flex text-center md:text-left md:justify-between pb-4 text-white border-t border-[#F7F7F7] bg-[#1b1b1b] pt-8">
        <div>
          <p>
            &#169;{new Date().getFullYear()} Beta Communes | All Rights Reserved
          </p>
        </div>
        <div className="flex space-x-5 font-medium">
          <Link to="/terms">
            {" "}
            <p className="cursor-pointer hover:text-[#EC4814] transition-all duration-300 ease-in-out">
              Terms and Conditions
            </p>{" "}
          </Link>
          <Link to="/policy">
            {" "}
            <p className="cursor-pointer hover:text-[#EC4814] transition-all duration-300 ease-in-out">
              Privacy Policy
            </p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Footer;
