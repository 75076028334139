import React , {useEffect, useState} from 'react'
import "./Score.css"
import CountUp from "react-countup";
import { score } from '../../data'
import AOS from "aos";
import "aos/dist/aos.css";

const Score = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000
        });
      }, []);
  const [countOn , setCountOn] = useState(false);

  return (
     <main className='main-box  md:px-10 ' onMouseEnter={() => setCountOn(true)}  >
        {
            score.map(({title , score}, index )  => {
                return(
                    <div className="score-box" key={index}  data-aos="fade-up" >
                        {
                     countOn &&  <h2><CountUp start={0} end={score} duration={5 }/>+</h2> 
                        }
                    <span>{title}</span>
                </div>
                )
            }  )
        }
       
     </main>
)
}

export default Score