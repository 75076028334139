import React from 'react'
import Team from '../../Components/Team/Team'
import Company from './Company/Company'
import Vision from './Vision/Vision'


const About = () => {
  return (
     <>
     <Company/>
     <Vision/>
       <Team/>

     </>
  )
}

export default About