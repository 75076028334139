import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TiTick } from "react-icons/ti";
import { MdOutlineOndemandVideo } from 'react-icons/md';
import { FaLaptopCode, FaMobileAlt } from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi'; 
import { PiCertificateBold } from "react-icons/pi"; // Ensure this is the correct icon and import path
import Coding from "../../../assets/codingExercise.jpg";
import Webbanner from "../../../assets/Webc.jpg";
import "./Coursedetails.css";

const Coursedetails = () => {
  const { state } = useLocation();
  const { name, price, keypoints,image, CourseIncludes , detailKeypoints } = state || {};
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!state) {
    return <div>Course data not available</div>;
  }

  const iconMapping = {
    "2 month Course": <MdOutlineOndemandVideo />,
    "3 months Course": <MdOutlineOndemandVideo />,
    "2 month Course": <MdOutlineOndemandVideo />,
    "2+ Projects + Coding exercises": <FaLaptopCode />,
    "3+ Projects + Coding exercises" : <FaLaptopCode/>,
    "Access on mobile and laptop": <FaMobileAlt />,
    "24 Hours Mentor support": <BiSupport />,
    "Certificate of Completion": <PiCertificateBold />,  
    "Free domain & Hosting": <FaLaptopCode />
  };

  return (
    <div className="courseDetail">
      <div className="details">
        <div className="grid-item details-left">
          <div className="learn-details"> 
            <span className='learn-title'>What you'll learn</span>
            <div className='list' >
              {detailKeypoints?.map((point, index) => (
                <li key={index}>
                  <TiTick className="list-icon" />
                  <span>{point}</span>
                </li>
              ))}
            </div>
          </div>
          <div className="coding-exercise">
            <span className='learn-title'>Coding Exercise</span>
            <div className='coding-content'>
              <span>After every class, our dedicated mentors provide tailored exercises to reinforce your learning. These exercises are designed to deepen your understanding and strengthen your skills. With personalized guidance, you'll not only grasp concepts better but also excel in applying them effectively. Elevate your learning experience with us!</span>
              <img src={Coding} className='coding' alt="Coding Exercise" />
            </div>
          </div>
        </div>
        <div className="grid-item details-right">
          <img src={Webbanner} className='card-course-img' alt="Course Banner" />
          <span className='price'>{`RS ${price}`}</span>
          <div className="course-include">
            <p>{name}</p>
            {CourseIncludes?.map(({ txt }, index) => (
              <div className="course-include-list" key={index}>
                <span className='txt-icon'>{iconMapping[txt]}</span>
                <span className='txt'>{txt}</span>
              </div>
            ))}
          </div>
          <button className="course-button" onClick={() => navigate("/enrollment")} type="button">
            Enroll Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default Coursedetails;
