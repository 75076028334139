import React from "react";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const CustomButtonGroupAsArrows = ({ next, previous }) => {
  return (
  <div className="flex absolute top-[-80px] md:top-[80%] lg:top-[88%] md:left-5  justify-center items-center 4 lg:mt-6"  data-aos="fade-right" >
      <button
        onClick={previous}
        className="mr-4 p-4 w-[60px] h-[60px] flex justify-center items-center rounded-[12px] hover:text-black cursor-pointer text-[#EC4814] text-[40px] font-bold transition-all duration-300 ease-in-out border-2 border-[#F7F7F7] hover:border-[#EC4814]"
      >
        <FaArrowLeftLong />
      </button>
      <button
        onClick={next}
        className="ml-2 p-4 w-[60px] h-[60px] flex justify-center items-center rounded-[12px] hover:text-black cursor-pointer text-[#EC4814] text-[40px] font-bold transition-all duration-300 ease-in-out border-2 border-[#F7F7F7] hover:border-[#EC4814]"
      >
        <FaArrowRightLong />
      </button>
    </div>
  );
};



const CustomButtonGroupAsArrows2 = ({ next, previous }) => {
  return (
    <div
      className="flex absolute  bottom-12 md:bottom-[130px] z-[100] right-8 sm:right-12 md:right-16 "
      data-aos="fade-right"
    >
      <button
        onClick={previous}
        className="mr-4  w-[40px] h-[40px] flex justify-center items-center rounded-full text-black cursor-pointer hover:text-[#EC4814] text-[20px] font-bold transition-all duration-300 ease-in-out border-2 border-[#0E191E19] hover:border-black"
      >
        <FaArrowLeftLong />
      </button>
      <button
        onClick={next}
        className="ml-0  w-[40px] h-[40px] flex justify-center items-center rounded-full text-black cursor-pointer hover:text-[#EC4814] text-[20px] font-bold transition-all duration-300 ease-in-out border-2 border-[#0E191E19] hover:border-black"
      >
        <FaArrowRightLong />
      </button>
    </div>
  );
};
export { CustomButtonGroupAsArrows,CustomButtonGroupAsArrows2 };
