import course1 from "./assets/course card/html.jfif";
import course2 from "./assets/course card/react.png";
import course3 from "./assets/course card/js.png";
import retroFurnix from "./assets/projects/pro1.png";
import mbasolutions from "./assets/projects/pro3.PNG";
import peacetransporation from "./assets/projects/pro2.png";


const img1 = "https://sobtech.vercel.app/assets/img/icon/chat.svg";
const img2 = "https://sobtech.vercel.app/assets/img/icon/content.svg";
const img3 = "https://sobtech.vercel.app/assets/img/icon/search.svg";

const team1 = "https://sobtech.vercel.app/assets/img/team/1.png";  
const team2 = "https://sobtech.vercel.app/assets/img/team/2.png";
const team3 = "https://sobtech.vercel.app/assets/img/team/3.png";
const team4 = "https://sobtech.vercel.app/assets/img/team/4.png";


export const Projects = [
  {
    id: 1,
    heading: "Custom Software Solutions",
    desc: "Developed tailored software solutions to meet unique business needs, enhancing efficiency and productivity.",
    img: img1,
  },
  {
    id: 2,
    heading: "Web Application Development",
    desc: "Built responsive and scalable web applications with modern technologies to provide seamless user experiences.",
    img: img2,
  },
  {
    id: 3,
    heading: "Mobile App Development",
    desc: "Created intuitive and engaging mobile applications for both iOS and Android platforms, driving user engagement and satisfaction.",
    img: img3,
  },
];




export const score = [
  // {
  //   id: 1,
  //   score: "220",
  //   title: "Winning Award"
  // },
  {
    id: 2,
    score: "10",
    title: "Complete Project"
  },
  {
    id: 3,
    score: "8",
    title: "Clients Review"
  },
  {
    id: 4,
    score: "13",
    title: "Team Members"
  }
]

export const team = [
  {
    id: 1,
    img: team1,
    name: "Bessie Cooper",
    skill: "Designer",
    profession: "Mentor",


  },
  {
    id: 2,
    img: team2,
    name: "Bessie Cooper",
    skill: "Designer",
    profession: "Mentor",

  },
  {
    id: 3,
    img: team3,
    name: "Bessie Cooper",
    skill: "Designer",
    profession: "Mentor",

  },
  {
    id: 4,
    img: team4,
    name: "Bessie Cooper",
    skill: "Designer",
    profession: "Mentor",

  }
]
export const cardData = [
  {
    name: "Frontend Development",
    duration: "2 months",
    price: "2500",
    image: course1,
    keypoints: [
      "HTML, CSS & BOOTSTRAP",
      "Responsive Design",
      "JavaScript Basics & Github",
    ],
    detailKeypoints: [
      "Learn the fundamentals of web development.",
      "Master responsive web design techniques.",
      "Understand JavaScript basics and GitHub.",
      "Work on real-world projects with mentor support."
    ],
    CourseIncludes: [
      { txt: "2 month Course" },
      { txt: "2+ Projects + Coding exercises" },
      { txt: "Access on mobile and laptop" },
      { txt: "24 Hours Mentor support" },
      { txt: "Certificate of Completion" },
      { txt: "Free domain & Hosting" },
    ]
  },
  {
    name: "Frontend with React.js",
    duration: "3 months",
    price: "5000",
    image: course2,
    keypoints: [
      "HTML, CSS & BOOTSTRAP",
      "JavaScript & Github",
      "Advanced React.js",
    ],
    detailKeypoints: [
      "Dive deep into modern frontend technologies.",
      "Develop single-page applications with React.js.",
      "Learn state management with Redux.",
      "Build complex UIs with reusable components."
    ],
    CourseIncludes: [
      { txt: "3 months Course" },
      { txt: "3+ Projects + Coding exercises" },
      { txt: "Access on mobile and laptop" },
      { txt: "24 Hours Mentor support" },
      { txt: "Certificate of Completion" },
      { txt: "Free domain & Hosting" },
    ]
  },
  {
    name: "Advanced JavaScript",
    duration: "2 months",
    price: "2000",
    image: course3,
    keypoints: [
      "JavaScript Basics",
      "ES6+ Features",
      "Async Programming & more...",
    ],
    detailKeypoints: [
      "Master advanced JavaScript concepts.",
      "Get hands-on with ES6+ features.",
      "Understand asynchronous programming.",
      "Work on challenging projects to solidify skills."
    ],
    CourseIncludes: [
      { txt: "2 month Course" },
      { txt: "2+ Projects + Coding exercises" },
      { txt: "Access on mobile and laptop" },
      { txt: "24 Hours Mentor support" },
      { txt: "Certificate of Completion" },
      { txt: "Free domain & Hosting" },
    ]
  },
];




export const project = [
  {
    id: 1,
    img: retroFurnix ,
    title: "Retro <span>Furnix</span> ",
    desc: "Elevate your brand with RetroFurnix.com – a bespoke E-commerce store meticulously crafted by our marketing agency. Seamless, stylish, and tailored for success. Discover excellence in online retail!",
    link: "https://retrofurnix.com/",
  },
  {
    id: 2,  
    img: mbasolutions,
    title: "Mba <span>Solutions</span>",
    desc: "The Primary purpose of MBA Solutions is to help the clients organization for resource development & implement the strategies properly for improvement. We help to develop execution and control plans to assure implementation across the country..",
    link: "https://www.mbasolutions.co/",
  },
  {
    id: 3,
    img: peacetransporation,
    title: "Peace <span>Transportation</span>",
    desc: "Elevated Peace Transportation's online presence with strategic SEO optimization. Our marketing agency delivered enhanced visibility, driving traffic and boosting the client's digital impact. Explore the success at peacetransportation.ca.",
    link: "https://peacetransportation.ca/",
  },
];

export const vision = [
    {
       id:1,
       heading:"Our Mission",
       desc:"At Beta Communes, we design, develop, and maintain cutting-edge web applications, while fostering a community of young talent through affordable training and collabo-rative opportunities, driving business growth and social impact."
    },
    {
        id:2,
        heading:"Our Vision",
        desc:"Empowering the next generation of innovators and entrep-reneurs through technology, education, and collaboration, creating a better future for all."
    },
  
]