import React from 'react'
import "./Company.css"

const Company = () => {
    return (
        <section className='company-main'>
            <div className='companyBackground'> </div>
            <div className="company-text">
                <h1>COMPANY <br /> </h1>
                <p>PROFILE</p>
                <span>Welcome to Beta Communes, a tech innovation hub where creativity meets community. <br />
                We design and develop cutting-edge websites and software, while empowering the next <br /> 
                generation of innovators through affordable education and collaboration opportunities. <br />
                  Learn, Grow and thrive with Us.. </span>
                
            </div>
 
        </section>
    )
}

export default Company