import React from 'react';
import "./Header2.css";
import { useLocation, Link } from 'react-router-dom';

const Header2 = () => {
    const location = useLocation();

    // Map pathnames to page names
    const pageNames = {
        '/aboutus': 'About Us',
        '/portfolio': 'Projects',
        '/contactus': 'Contact Us',
        '/services' : 'Services',
        "/courses": 'Courses',
        "/enrollment": 'Enrollment',
        "/coursedetails":"Course Details"
   
    }; 

    // Get the current page name based on the pathname
    const currentPageName = pageNames[location.pathname] || '';
  
    // Only render the header for pages other than home
    if (location.pathname === '/') {
        return null;
    }
  
    return (
        <header className="header2">
          <div className='third'></div>
            <div className="background-image"></div>
            <div className="header-content">
                <h1>{currentPageName}</h1>
                <nav className="breadcrumb">
                    <Link to="/">Home</Link> / {currentPageName}
                </nav>
            </div>
        </header>
    );
};

export default Header2;
