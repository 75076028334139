import React, { useState, useEffect } from 'react';
import './EnrollForm.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Notification from './Notification/Notification';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import { AiFillFileImage } from 'react-icons/ai';
import { animateScroll as scroll } from "react-scroll";
import AOS from 'aos';
import 'aos/dist/aos.css';

const EnrollForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    photo: null,
    category: '', 
  });
  const [enrollmentSuccess, setEnrollmentSuccess] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [image, setImage] = useState(null);
  const [fileName, setFileName] = useState('No selected file');

  const spans = Array.from({ length: 50 }, (_, i) => (
    <span key={i} style={{ '--i': i }}></span>
  ));

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setFileName(files[0].name);
      setImage(URL.createObjectURL(files[0]));
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.info('Sending data...');

   

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      await axios.post('https://api.betacommunes.com/enroll', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        icon: 'success',
        title: 'Enrollment Successful',
        text: 'You have successfully enrolled!',
        confirmButtonText: 'OK',
      }).then(() => {
        setShowNotification(true);
        setFormData({ name: '', email: '', phone: '', photo: null, category: '' });
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
        scroll.scrollToTop();
        navigate('/'); 
      });

    } catch (error) {
      console.error('Error:', error);
      toast.error('Enrollment failed. Please try again.');
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({ duration: 1000 }); 
  }, [pathname]);

  return (
    <>
      <div className="main-form urbanist">
        <div className="container-enroll shadow-xl my-16" data-aos="fade-up">
          <div className="enroll-box">
            <h2 className="enroll">Enrollment</h2>
            <form onSubmit={handleSubmit}>
              <div className="input-box">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  data-aos="fade-right"
                />
                <label>Name</label>
              </div>
              <div className="input-box">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  data-aos="fade-right"
                />
                <label>Email</label>
              </div>
              <div className="input-box">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  data-aos="fade-right"
                />
                <label>Whatsapp No</label>
              </div>
              <div className="input-box">
                <select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  required
                  data-aos="fade-right"
                >
                  <option value="" disabled>Select a Course</option>
                  <option value="frontend-react">Frontend with React.js</option>
                  <option value="frontend-no-react">Frontend without React.js</option>
                  <option value="advanced-js">Advanced JavaScript</option>
                  <option value="graphic-design">Graphic Designing</option>
                  <option value="digital-marketing">Digital Marketing</option>
                </select>
                <label className="select-label">Select a Course</label>
              </div>
              <div
                className="image-upload"
                onClick={() => document.querySelector('.iamge-field').click()}
                data-aos="fade-right"
              >
                <input
                  type="file"
                  name="photo"
                  className="iamge-field"
                  onChange={handleChange}
                  accept="image/*"
                  required
                  hidden
                />
                {image ? (
                  <img src={image} width={150} height={150} alt={fileName} />
                ) : (
                  <>
                    <MdCloudUpload color="#ff4500" size={60} />
                    <p>Upload Fee Receipt</p>
                  </>
                )}
              </div>
              <section className="uploaded-row" data-aos="fade-right">
                <AiFillFileImage color="#ff4500" />
                <span className="upload-content">
                  {fileName} - 
                  <MdDelete
                    onClick={() => {
                      setFileName('No selected file');
                      setImage(null);
                    }}
                  />
                </span>
              </section>
              <button type="submit" className="btn" data-aos="fade-up">Enroll Now</button>
            </form>
          </div>
          {spans}
        </div>

        {showNotification && <Notification />}
        <ToastContainer />
      </div>
    </>
  );
};

export default EnrollForm;
