import React from 'react'
import "./Vision.css"
import visionImg from "../../../assets/mission.png"
import { vision } from '../../../data'


const Vision = () => {
  return (
    <section className='vision-main'>
      <div className='line'></div>
      <h1>MISSION VISION <span>VALUES</span></h1>
      <aside className='vision-card'>
        <div className="vison-left">
          {
            vision.map(({ heading, desc }, index) => {
              return(
                <div className="vision-box" key={index}>
                <span>{heading}</span>
                <p>{desc}</p>
              </div>
              )
             
            })
          }

        </div>
        <div className="vision-right">
          <img src={visionImg} alt="" />
        </div>


      </aside>
    </section>
  )
}

export default Vision
