import React from 'react'
import Portfolio from "../../Components/Portfolio/Portfolio.jsx"
import Services from "../../Components/Services/Services.jsx"
import Score from '../../Components/Score/Score.jsx'
import Header from '../../Components/Header/Header.jsx'
import PricingCard from "../../Components/PricingCard/PricingCard.jsx"
import Testimonial from "../../Components/testominal/TestimonialSection.jsx" ;
import ChooseUs from '../../Components/Chooseus/ChooseUs.jsx'
import Projects from "../../Components/Projects/Projects.jsx"

const Home = () => {
  return (
    <div>
      <Header/>
      <Portfolio/>
      {/* <ChooseUs/> */}
      <Services/> 
      {/* <PricingCard/> */}
      <Projects/>
      <Score/>
      <Testimonial/>
    </div>  
  )
}

export default Home