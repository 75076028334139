import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import img from "../../assets/team/1.jpeg";
import img2 from "../../assets/team/2.jpeg";
import img3 from "../../assets/team/3.jpeg";                                                                                                  
const Team = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const teamMembers = [
    {
      name: "Muhammad Zain",
      designation: "Co-Founder | Full Stack Developer",
      img: img,
      skills: [
        { name: "Mentor", proficiency: 85 },
        { name: "Development", proficiency: 90 },
      ],
    },
    {
      name: "Asad Ali",
      designation: "Co-Founder | Full Stack Developer",
      img: img2,
      skills: [
        { name: "Mentor", proficiency: 95 },
        { name: "Development", proficiency: 85 },
      ],
    },
    {
      name: "Sheeza ",
      designation: "Co-Founder | Digital Marketer",
      img: "https://via.placeholder.com/150",
      skills: [
        { name: "Mentor", proficiency: 85 },
        { name: "Marketing", proficiency: 90 },
      ],
    },
    {
      name: "Ali Hamza",
      designation: "Graphic Designer| UI/UX",
      img: img3,
      skills: [
        { name: "Mentor", proficiency: 95 },
        { name: "Designing", proficiency: 85 },
      ],
    },
  ];

  return (
    <div className="py-16 px-6 bg-gray-100 urbanist">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1
          className="text-xl text-[#ec5c14] font-bold text-center"
          data-aos="fade-up"
        >
          Our Team
        </h1>
        <h2
          className="text-heading font-[900] text-[#0e191e] text-3xl lg:text-[40px] max-w-2xl mx-auto leading-[55px] mt-[18px] mb-[60px] text-center"
          data-aos="fade-up"
        >
          Meet our skilled and dedicated IT professionals
        </h2>
        <div className="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-6">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-[#FFFAF0] rounded-xl shadow-xl flex py-6 sm:py-0 overflow-hidden"
              data-aos="fade-up"
            >
              <div className="pl-6 pr-10 flex flex-col mt-6 sm:mt-8 w-full lg:w-[55%]">
                <h3
                  className="text-[24px] md:text-[29px] leading-[45px] font-[900] text-heading"
                  data-aos="fade-right"
                >
                  {member.name}
                </h3>
                <p className="text-gray-600" data-aos="fade-right">
                  {member.designation}
                </p>
                <div className="md:mt-6">
                  {member.skills.map((skill, i) => (
                    <div key={i} className="mb-4">
                      <div className="flex justify-between">
                        <span className="text-sm text-gray-600">
                          {skill.name}
                        </span>
                      </div>
                      <div className="w-full bg-gray-200 overflow-hidden my-3 h-2 rounded-full">
                        <div
                          className="bg-[#ec5c14]  h-2 rounded-full skill-bar"
                          data-aos="fade-right"
                          style={{ width: `${skill.proficiency}%` }}
                        ></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full md:w-[42%] lg:w-[45%]">
                <img
                  className="object-cover rounded-xl md:ml-1 w-full h-full"
                  src={member.img}
                  alt={member.name}
                  data-aos="fade-left"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
