import React from 'react'
import { FaTimes } from 'react-icons/fa'
import "./Mobileview.css"
import { Link, useNavigate } from 'react-router-dom'

const Mobileview = ({setshow}) => {
    const navigate =  useNavigate();
    
  return (
    <div className="mobileview">
      <div className="top">
        <div className="logo">  
          Beta <span className="commune">Communes</span>
        </div>
        <div className="fatimes">
          <FaTimes onClick={() => { setshow(false) }} className='cross' />

        </div>
      </div>
      <div className="middle">
        <ul className='mobilemenu' onClick={() => { setshow(false) }}  >
        <li onClick={() => navigate("/")} >Home</li>
            <li onClick={() => navigate("/aboutus")} >About Us</li>
            <li onClick={() => navigate("/portfolio")}>Projects</li>
            <li onClick={() => navigate("/courses")}>Courses</li>
            <li onClick={() => navigate("/enrollment")} >Enrollment</li>

            <Link to='/contactus'>
      <div className='nav-button2'>
    
        <span>Connect With Us</span>
        
      </div>
      </Link>
        </ul>
       
      </div>
      </div>
  )
}

export default Mobileview