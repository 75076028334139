import React, { useState, useEffect } from 'react';
// import './MouseFollower.css';

const MouseFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className=""
      
    >
             <div
         style={{
            left: `${position.x+7}px`,
            top: `${position.y+17}px`,
            width: '7px',
            height: '7px',
            backgroundColor: 'black', // Temporary background color for visibility
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            pointerEvents: 'none',
            zIndex: '9999',
         }}
        ></div>

        <div style={{
        left: `${position.x+13}px`,
        top: `${position.y+22}px`,
        width: '7px',
        height: '7px',
        backgroundColor: 'orangered', // Temporary background color for visibility
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        pointerEvents: 'none',
        zIndex: '9999',
      }}>
{/* 
        </div>
        <div  style={{
        left: `${position.x+35}px`,
        top: `${position.y+46}px`,
        width: '7px',
        height: '7px',
        backgroundColor: 'red', // Temporary background color for visibility
        position: 'fixed',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        pointerEvents: 'none',
        zIndex: '9999',
      }}></div>
    */}
    </div>

    </div>
  );
};

export default MouseFollower;
