import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./Portfolio.css";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Projects } from "../../data";
import { animateScroll as scroll } from "react-scroll";

const Portfolio = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    });
  }, []);

  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  return (
    <section className='portfolio-main px-4 md:px-10 py-10 space-x-0  justify-center items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4'>
      {Projects.map(({ img, heading, desc }, index) => (
        <div 
          className="portfolio-card md:h-[390px] lg:h-[420px] xl:h-[380px] noselect" 
          key={index}
          data-aos="fade-up" 
        >
          <img src={img} alt={heading} />
          <h3>{heading}</h3>
          <span>{desc}</span>
          <button 
            className='text-black hover:text-red-500 hover:ml-2 hover:translate-x-2 cursor-pointer' 
            onClick={handleLinkClick}
          >
            More about
            <FaLongArrowAltRight fontSize={20} fontWeight={300} className='mt-1'/>
          </button>
        </div>
      ))}
    </section>
  );
};

export default Portfolio;
