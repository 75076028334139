import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import { CustomButtonGroupAsArrows2 } from "../../helper/CustomArrows";
import bg from "../../assets/testominals/2.jpg"

const testimonials = [
  {
    name: "James Anderson",
    role: "UI/UX Designer",
    text: "The UI/UX design services offered have transformed our user experience. The intuitive designs and user-friendly interfaces they created have significantly improved user engagement.",
    stars: 5,
  },
  {
    name: "Jane Doe",
    role: "Web Developer",
    text: "The web development team delivered an outstanding website that met all our needs. Their attention to detail and technical expertise were evident throughout the project.",
    stars: 4,
  },
  {
    name: "John Smith",
    role: "Digital Marketing Specialist",
    text: "Their digital marketing strategies have been a game-changer for our brand. The targeted campaigns and creative solutions have driven excellent results.",
    stars: 5,
  },
  {
    name: "Emily Johnson",
    role: "Graphic Designer",
    text: "The graphic design services exceeded our expectations. The visuals they created were not only stunning but also perfectly aligned with our brand's message.",
    stars: 4,
  },
  {
    name: "Michael Brown",
    role: "Product Manager",
    text: "The comprehensive approach to both development and marketing services played a crucial role in our product launch. Their support and expertise were invaluable.",
    stars: 5,
  },
];


const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },

  tablet: {
    breakpoint: { max: 3000, min: 0 },
    items: 1,
  },
};

const TestimonialSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className="urbanist h-auto md:h-[870px]  pt-[60px] lg:pt-[120px] px-4 lg:px-8">
      <div className="container">
        <div className="flex justify-between flex-col gap-5 md:flex-row">
          <div>
            <h1 className="flex h-fit items-center gap-2.5" data-aos="fade-up">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_13847)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.3087 10.3902H10.9406L16.1511 15.6007L15.5987 16.153L10.3882 10.9425V18.3107H9.61V10.9425L4.39949 16.153L3.84711 15.6007L9.05762 10.3902H1.68945V9.61195H9.05762L3.84711 4.40145L4.39949 3.84906L9.61 9.05957V1.69141H10.3882V9.05957L15.5987 3.84906L16.1511 4.40145L10.9406 9.61195H18.3087V10.3902Z"
                  fill="#5B5B5B"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.12418 2.82445C4.84203 2.82445 5.42391 3.40637 5.42391 4.12418C5.42391 4.84203 4.84199 5.42391 4.12418 5.42391C3.40637 5.42391 2.82445 4.84199 2.82445 4.12418C2.82445 3.40637 3.40637 2.82445 4.12418 2.82445ZM17.1755 4.12418C17.1755 4.84203 16.5936 5.42391 15.8758 5.42391C15.158 5.42391 14.5761 4.84199 14.5761 4.12418C14.5761 3.40637 15.158 2.82445 15.8758 2.82445C16.5936 2.82445 17.1755 3.40637 17.1755 4.12418ZM18.3096 11.2997C17.5918 11.2997 17.0099 10.7178 17.0099 10C17.0099 9.28215 17.5918 8.70027 18.3096 8.70027C19.0275 8.70027 19.6094 9.28219 19.6094 10C19.6094 10.7179 19.0275 11.2997 18.3096 11.2997ZM11.2997 1.69035C11.2997 2.4082 10.7178 2.99012 10 2.99012C9.28215 2.99012 8.70027 2.4082 8.70027 1.69035C8.70027 0.972539 9.28219 0.390625 10 0.390625C10.7179 0.390625 11.2997 0.972539 11.2997 1.69035ZM15.8758 17.1755C15.158 17.1755 14.5761 16.5936 14.5761 15.8758C14.5761 15.158 15.158 14.5761 15.8758 14.5761C16.5936 14.5761 17.1755 15.158 17.1755 15.8758C17.1755 16.5936 16.5936 17.1755 15.8758 17.1755ZM8.70027 18.3096C8.70027 17.5918 9.28219 17.0099 10 17.0099C10.7179 17.0099 11.2997 17.5918 11.2997 18.3096C11.2997 19.0275 10.7178 19.6094 10 19.6094C9.28215 19.6094 8.70027 19.0275 8.70027 18.3096ZM2.82445 15.8758C2.82445 15.158 3.40637 14.5761 4.12418 14.5761C4.84203 14.5761 5.42391 15.158 5.42391 15.8758C5.42391 16.5936 4.84199 17.1755 4.12418 17.1755C3.40637 17.1755 2.82445 16.5936 2.82445 15.8758ZM1.69035 8.70027C2.4082 8.70027 2.99012 9.28219 2.99012 10C2.99012 10.7179 2.4082 11.2997 1.69035 11.2997C0.972539 11.2997 0.390625 10.7178 0.390625 10C0.390625 9.28215 0.972539 8.70027 1.69035 8.70027Z"
                  fill="#103A33"
                ></path>
                <path
                  d="M13.6201 13.622C15.6205 11.6216 15.6205 8.37835 13.6201 6.37795C11.6197 4.37755 8.37636 4.37755 6.37596 6.37795C4.37555 8.37835 4.37555 11.6216 6.37596 13.622C8.37636 15.6225 11.6197 15.6225 13.6201 13.622Z"
                  fill="#EC4814"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.3078 12.074C12.3078 13.3482 7.69336 13.3482 7.69336 12.074C7.69336 10.7998 8.72645 9.76684 10.0007 9.76684C11.2748 9.76688 12.3078 10.7998 12.3078 12.074ZM8.9343 8.03883C8.9343 8.62777 9.41172 9.1052 10.0007 9.1052C10.5896 9.1052 11.067 8.62777 11.067 8.03883C11.067 7.45004 10.5896 6.97266 10.0007 6.97266C9.41168 6.97262 8.9343 7.45004 8.9343 8.03883Z"
                  fill="white"
                ></path>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.2543 11.5781C11.9358 12.6196 8.26301 12.6534 7.72703 11.6794C7.70488 11.8077 7.69336 11.9396 7.69336 12.0743C7.69336 13.3484 12.3078 13.3484 12.3078 12.0743C12.3078 11.9039 12.2894 11.7379 12.2543 11.5781Z"
                  fill="#1A2429"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1_13847">
                  <rect width="20" height="20" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
              <span className="text-xl font-semibold text-[#EC4814]">
                Clients Testimonial
              </span>
            </h1>
            <h2
              className="text-[40px] leading-[45px] text-[#0E191E] font-bold text-heading capitalize mt-4"
              data-aos="fade-up"
            >
              Unlock the potential <br />
              of design
              <span className="text-[#EC4814]"> technology</span>
            </h2>
          </div>
          <p
            className="text-lg text-paragraph text-[#5B5B5B] max-w-[390px] w-full"
            data-aos="fade-up"
          >
            Working with this company has been a game-changer for my business.
            Their expertise and innovative approach helped us achieve remarkable
            results.
          </p>
        </div>

        <div className="pt-10 ">
          <img
            src={bg}
            alt="Testominals"
            className="hidden absolute h-[550px] md:block z-[-10] rounded-md"
            data-aos="fade-up"
          />
          <div className="w-full md:w-[670px] mt-2 my-auto relative md:absolute right-0">
            <Carousel
              responsive={responsive}
              autoPlay
              infinite={true}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<CustomButtonGroupAsArrows2 />}
            >
              {testimonials.map((item, index) => (
                <div
                  key={index}
                  className="h-auto sm:h-[320px] sm:ml-5 md:ml-0 md:h-[480px] w-full max-w-[630px] relative flex flex-col justify-center my-auto items-center"
                  data-aos="fade-up"
                >
                  <div className="h-auto md:h-[270px] shadow-md pb-24 p-7 rounded-[20px] bg-[#FFFAF0]">
                    <div className="flex justify-between">
                      <div>
                        <h3 className="text-xl font-semibold">{item.name}</h3>
                        <p className="text-md mt-2 text-black/80">{item.role}</p>
                      </div>
                      <svg
                        width="56"
                        height="40"
                        viewBox="0 0 56 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 0C18.625 0 24 5.375 24 12V24C24 32.875 16.75 40 8 40C5.75 40 4 38.25 4 36C4 33.875 5.75 32 8 32C12.375 32 16 28.5 16 24V23.375C14.625 23.75 13.375 24 12 24C5.25 24 0 18.75 0 12C0 5.375 5.25 0 12 0ZM56 12V24C56 32.875 48.75 40 40 40C37.75 40 36 38.25 36 36C36 33.875 37.75 32 40 32C44.375 32 48 28.5 48 24V23.375C46.625 23.75 45.375 24 44 24C37.25 24 32 18.75 32 12C32 5.375 37.25 0 44 0C50.625 0 56 5.375 56 12Z"
                          fill="#EC4814"
                          mask="url(#path-1-inside-1_1_14367)"
                        ></path>
                      </svg>
                    </div>
                    <p className="text-lg mt-3">{item.text}</p>

                    <div className="flex mt-12">
                      {[...Array(item.stars)].map((_, i) => (
                        <svg
                          key={i}
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="gold"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 1.618L12.088 6.536L17.606 7.18L13.036 11.272L14.071 16.794L10 13.774L5.929 16.794L6.964 11.272L2.394 7.18L7.912 6.536L10 1.618Z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
