import React from 'react'
import Contacts from "../../Components/Contact/Contact";

const Contact = () => {
  return (
    <div>
      <Contacts />

    </div>
  )
}

export default Contact