import React, { useEffect } from 'react';
import "./Header.css";
import Typewriter from 'typewriter-effect';
import Banner from "../../assets/banner.png";
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Header = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <>
      <main className='mainbody'>
        <div className="animate-text" data-aos="fade-up">
          <span className=''>
            <span className='lilita'>
              <Typewriter
                options={{
                  strings: [
                    'BRID<span style="color:  #ff4500;" >G</span>ING<br/> I<span style="color:   #ff4500 ;" >N</span>NOVATION',
                    'B<span  style="color: #ff4500;" >U</span>ILDING<br/> COMM<span style="color:  #ff4500 ;" >U</span>NITIES'
                  ],
                  autoStart: true,
                  loop: true,
                  cursor: '|',
                  cursorSpeed: 500,
                  deleteSpeed: 100,
                }}
              />
            </span>
          </span>

          <div className="content" data-aos="fade-up" data-aos-delay="200">
            <p>Elevating businesses since 2022, we've crafted cutting-edge IT solutions for global success.<br /> Transform your digital journey with us today!</p>
          </div>

          <div className="button" data-aos="fade-up" data-aos-delay="400">
            <button
              className="workwithus"
              onClick={() => navigate('/contact')}
            >
              Connect for Excellence 
            </button>
          </div>
        </div>

        <div className="img" data-aos="fade-left">
          <img src={Banner} style={{ width: "100%", height: "100%" }} alt="Banner" />
        </div>
      </main>
    </>
  );
}

export default Header;
