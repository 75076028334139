import React, { useState, useEffect } from 'react';
import "./Navbar.css";
import { FaBarsStaggered } from "react-icons/fa6";
import Mobileview from './Mobileview/Mobileview';
import { NavLink } from 'react-router-dom'; // Import NavLink
import logo from "../../assets/services/logo.png";
import { animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [shadow, setShadow] = useState(false);
  
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`nav-main urbanist ${shadow ? 'shadow-xl' : ''}`}>
      <div className='logo '>
        <NavLink to='/'>
        <div className=' hidden md:flex  justify-center items-center'>
          <img src={logo} width={100} height={100} alt="Beta Communes"  onClick={handleLinkClick} />
          {/* <p>Beta Communes</p> */}
          </div>
          <div className=' md:hidden flex  justify-center items-center'>
          <img src={logo} width={100} height={100} alt="Beta Communes"  onClick={handleLinkClick} />
          {/* <p>Beta Communes</p> */}
          </div>
        </NavLink>
      </div>
      <div className='lists'>
        <ul>
          <li>
            <NavLink 
              to='/' 
              onClick={handleLinkClick} 
              className={({ isActive }) => `list-items ${isActive ? 'active' : ''}`}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink 
              to='/aboutus' 
              onClick={handleLinkClick} 
              className={({ isActive }) => `list-items ${isActive ? 'active' : ''}`}
            >
              About Us
            </NavLink>
          </li>
        
          <li>
            <NavLink 
              to='/courses' 
              onClick={handleLinkClick} 
              className={({ isActive }) => `list-items ${isActive ? 'active' : ''}`}
            >
              Courses
            </NavLink>
          </li>
          <li>
            <NavLink 
              to='/enrollment' 
              onClick={handleLinkClick} 
              className={({ isActive }) => `list-items ${isActive ? 'active' : ''}`}
            >
              Enrollment
            </NavLink>
          </li>
        </ul>
      </div>
      <NavLink to='/contactus'>
        <div className='nav-button shadow-lg hover:shadow-xl'>
          <span>Connect With Us</span>
        </div>
      </NavLink>
      <div className="nav-icon">
        <FaBarsStaggered onClick={() => setShow(true)} />
      </div>
      {show && <Mobileview setshow={setShow} />}
    </nav>
  );
};

export default Navbar;
